@use "sass:math";

.providers {
  .provider {
    @media screen and (min-width: (map-get($breakpoints, "small") + "px")) {
      width: math.div(100%, 2);
    }
    @media screen and (min-width: (map-get($breakpoints, "large") + "px")) {
      width: math.div(100%, 3);
    }
    @media screen and (min-width: (map-get($breakpoints, "xlarge") + "px")) {
      width: math.div(100%, 4);
    }
    cursor: default;
    display: block;
    .fa-check {
      color: #8bc34a;
    }
    .fa-plus {
      color: #00BCD4;
    }
    span, a {
      padding-left: 8px;
    }
    a:link {
      text-decoration: underline;
    }
  }
}
