$code--tag: #e06c75;
$code--attr-name: #d19a66;
$code--attr-value: #98c379;
$code--punctuation: #607d8b;

/**
 * prism.js Coy theme for JavaScript, CoffeeScript, CSS and HTML
 * Based on https://github.com/tshedor/workshop-wp-theme (Example: http://workshop.kansan.com/category/sessions/basics or http://workshop.timshedor.com/category/sessions/basics);
 * @author Tim  Shedor
 */

code[class*="language-"],
pre[class*="language-"] {
  color: black;
  background: none;
  // font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;

  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
  position: relative;
  margin: 0.5em 0;
  overflow: visible;
  padding: 0;
}
pre[class*="language-"] > code {
  position: relative;
  border-left: 10px solid #90A4AE;
  box-shadow: -1px 0px 0px 0px #90A4AE, 0px 0px 0px 1px #dfdfdf;
  background-color: #fdfdfd;
}

code[class*="language"] {
  max-height: inherit;
  height: inherit;
  padding: 0.5em 1em;
  display: block;
  overflow: auto;
  border-radius: 0.3em;
}

/* Margin bottom to accomodate shadow */
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background-color: #fdfdfd;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin-bottom: 1em;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  position: relative;
  padding: 0.2em;
  border-radius: 0.3em;
  color: #c92c2c;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: inline;
  white-space: normal;
}

.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #7d8b99;
}

.token.punctuation {
  color: $code--punctuation;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.function-name,
.token.constant,
.token.symbol,
.token.deleted {
  color: $code--tag;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.function,
.token.builtin,
.token.inserted {
  color: $code--attr-name;
}

.token.operator,
.token.entity,
.token.url,
.token.variable {
  color: #a67f59;
  background: rgba(255, 255, 255, 0.5);
}

.token.atrule,
.token.attr-value,
.token.keyword,
.token.class-name {
  color: $code--attr-value;
}

.token.regex,
.token.important {
  color: #e90;
}

.language-css .token.string,
.style .token.string {
  color: #a67f59;
  background: rgba(255, 255, 255, 0.5);
}

.token.important {
  font-weight: normal;
}

.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}

.namespace {
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  pre[class*="language-"]:before,
  pre[class*="language-"]:after {
    bottom: 14px;
    box-shadow: none;
  }
}

/* Plugin styles */
.token.tab:not(:empty):before,
.token.cr:before,
.token.lf:before {
  color: #e0d7d1;
}

/* Plugin styles: Line Numbers */
pre[class*="language-"].line-numbers.line-numbers {
  padding-left: 0;
}

pre[class*="language-"].line-numbers.line-numbers code {
  padding-left: 3.8em;
}

pre[class*="language-"].line-numbers.line-numbers .line-numbers-rows {
  left: 0;
}

/* Plugin styles: Line Highlight */
pre[class*="language-"][data-line] {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
pre[data-line] code {
  position: relative;
  padding-left: 4em;
}
pre .line-highlight {
  margin-top: 0;
}

pre.language {
  @extend pre, [class*=language-];
}

code.language {
  @extend code, [class*=language-];
  @extend code, [class*=language];
}

code {
  color: $code--punctuation;

  .method {
    color: $code--attr-value;
  }
  .param-name {
    color: $code--attr-name;
  }
  .param-value {
    color: $code--tag;
  }
}
