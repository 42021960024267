.site-app {

  &--input {
    @media screen and (min-width: (map-get($breakpoints, "large") + "px")) {
      width: 40%;
    }
  }
  
  &--output {
    @media screen and (min-width: (map-get($breakpoints, "large") + "px")) {
      width: 60%;
    }

    grid-template-columns: 1fr 5fr;
    .output-frame {
      border-radius: 0px 0px 0.3em 0.3em;
      overflow: hidden;
      // box-shadow: 1px 1px 1px #747474;
      > div {
        color: $color-text-dark;
        &.preview {
          font-size: 0;
          line-height: 0;
          position: relative;
          text-align: center;
          // background: #fff;

          img {
            width: auto;
            max-height: 500px;
          }
        }
        &.markdown {
          background: #fff;
          margin-top: 2px;
          display: block;
          > pre {
            margin: 0;
            > code {
              border-radius: unset;
              border: none;
            }
          }
        }
      }
    }

    @media screen and (max-width: ((map-get($breakpoints, "large") - 1) + "px")) {
      .output-frame {
        width: 100vw;
        margin: 0 -1.5em -2.5em;
        border-radius: unset;
      }
    }
  }
}

form {

  position: relative;
  top: 50%;
  transform: translateY(-50%);

  label {
    position: relative;
    color: $color-white;
    font-weight: bold;
    display: block;
    margin-top: 15px;
    width: 100%;
    letter-spacing: 1px;

    .input[type="checkbox"] {
      margin-right: 10px;
    }

    .input[type="text"], .input[type="url"] {
      color: initial;
      width: 100%;
      margin: 0 0 15px;
      height: 40px;

      &[name="url"] {
        padding-right: 40px;

        ~ img {
          position: absolute;
          bottom: 15px;
          right: 0px;
          width: 40px;
          height: 40px;
          padding: 9px;
          border-left: 1px solid $header-background-color;
        }
      }
    }
  }
}

.buttons {
  text-align: center;

  .button {
    margin: 30px 0;
  }
}

.donations {
  background: #f6f3f4;
  border-bottom: 1px solid #EF9A9A;
  > div {
    position: relative;
    z-index: 1;
    background: #f6f3f4;
    padding: 8px 20px;
    border-radius: 3px;
  }
  .close {
    width: 24px;
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 16px;
    height: 24px;
    box-sizing: content-box;

    opacity: 0;
    transition: opacity $transition-duration;
    pointer-events: none;

    svg {
      pointer-events: none;
      width: 100%;
      height: 100%;
      fill: $color-text-dark
    }
  }
  a {
    white-space: nowrap;
    color: #0288D1;
    &.beer,
    &.sponsor,
    &.host {
      img {
        width: auto;
        height: 20px;
        vertical-align: text-bottom;
        margin: 0px 1px 1px;
      }
    }
    &.beer {
      color: #f85d5d;
    }
    &.sponsor {
      color: #db61a2;
      svg {
        fill: #db61a2;
      }
    }
    &.star {
      color: #F9A825;
      svg {
        fill: #F9A825;
      }
    }
    &.host {
      color: #14bbab;
    }
    svg {
      vertical-align: text-top;
    }
  }
}

body {
  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $header-background-color;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    transition: opacity $transition-duration;
  }
  &.modal {
    overflow: hidden;
    .container.donations {
      max-height: 100vh;
      overflow: auto;

      .close {
        opacity: 1;
        pointer-events: all;
      }
    }

    &:before {
      opacity: 0.7;
      pointer-events: all;
    }
  }
}
