.row {
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  padding: 2em 0;

  @media screen and (min-width: (map-get($breakpoints, "large") + "px")) {
    margin: 0 -15px;
  }

  > * {
    @media screen and (min-width: (map-get($breakpoints, "large") + "px")) {
      padding: 0 15px;
    }
    width: 100%;
  }
}

@mixin container-padding($max-width: 100vw) {
  $padding-tb: 1rem;
  $padding-lr: 1.5rem;
  padding: $padding-tb calc(#{$padding-lr} + 50vw - #{$max-width} / 2);
}

.container {
  position: relative;
  @include container-padding;

  @media screen and (min-width: (map-get($breakpoints, "large") + "px")) {
    @include container-padding(960px);
  }
  
  @media screen and (min-width: (map-get($breakpoints, "xlarge") + "px")) {
    @include container-padding(1152px);
  }
  
  @media screen and (min-width: (map-get($breakpoints, "xxlarge") + "px")) {
    @include container-padding(1344px);
  }

  > section {
    padding: {
      top: 2em;
      bottom: 3em;
    }
    border-top: 1px solid #dcdcdc;
    opacity: 1;
    transition: opacity $transition-duration;

    &:first-child {
      border-top: none;
    }

    &.hidden {
      opacity: 0;
      max-height: 0;
      padding: 0;
    }
  }
}

