// colors
$background-color: #f5f5f5;

$color-black: #000;
$color-black-transparent: transparentize($color-black, 0.65);

$color-white: #fff;

$color-text-light: #fff;
$color-text-dark: #37474f;

$header-background-color: #42474b;

$transition-duration: .5s;

$breakpoints: (
  xxsmall: 0,
  xsmall: 480,
  small: 640,
  medium: 720,
  large: 1024,
  xlarge: 1280,
  xxlarge: 1440
) !default;
